import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
  PERIODICITY_TYPE_MULTIPLE,
  SCHOOL_TYPE,
  SINGLE_LESSON_CATEGORY,
  PACK_OF_LESSON_CATEGORY,
  CASH_OF_LESSON_CATEGORY,
  FREE_OF_LESSON_CATEGORY,
  COURSE_LESSON_CATEGORY,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  stringify,
  parse,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  getInitiateOrderData,
  getListingDataForMail,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  IconProfileCard,
  FreeLessonCard,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
// import SectionAuthorMaybe from './SectionAuthorMaybe';
// import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';
import { ListingPageTab } from '../../config/configGlobal';
import { createResourceLocatorString } from '../../util/routes';
import { transitions } from '../../transactions/transactionProcessPurchase';
import { onCashLessonIntiateOrder, onFreeLessonIntiateOrder } from '../CheckoutPage/CheckoutPage.duck';

import css from './ListingPage.module.css';
import JoinCourseDetaiForm from '../../components/JoinCourseDetaiForm/JoinCourseDetaiForm.js';
import { omit } from 'lodash';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const SessionCard = (props) => {
  const { sessionListings, listing, timeSlots } = props
  // const listingId = listing?.id?.uuid;
  // const isPackListing = listing?.attributes?.publicData?.lessonCategory == "PACK";
  const city = listing?.attributes?.publicData?.city;

  const filterSlots = timeSlots && timeSlots.length ? timeSlots.filter((st, i) => st.attributes.start && st.attributes.end).sort((a, b) => moment(a.attributes.start).unix() - moment(b.attributes.end).unix()).filter((st, i) => i < 3) : [];

  return (
    <div className={css.nextSession}>
      <h2><FormattedMessage id="ListingPage.nextSession" /></h2>
      <div className={css.sessionListCard}>
        {filterSlots && filterSlots.length ?
          filterSlots.map((item, index) => {
            const { title, lessonName, dayAvailability = [], start, parentLessonName, startTime, endTime, seats, day, locationName, timeSlotSeats = 1 } = item.attributes || {}
            const findIndex = dayAvailability.length ? dayAvailability.findIndex((item) => item.day == day) : -1;
            const surrentSeatBooked = seats - timeSlotSeats;
            return (
              <div className={css.sessionCard} key={"sessionCard" + index}>
                <div className={css.sessonRow}>
                  <div className={css.headingName}>{lessonName || title}</div>
                  <div className={css.sessionDate}><span className={css.sessionDateCaptilize}>{moment(start).locale("es").format(`dddd DD`)}</span><span>, de {moment(start).locale("es").format(`MMMM`)}</span> </div>
                </div>
                {/* <div className={css.sessonRow}> Start Date : {moment(start).format(`DD-MM-YYYY`)}</div>
                <div className={css.sessonRow}>End Date : {moment(end).format(`DD-MM-YYYY`)}</div> */}

                {/* {periodicity == PERIODICITY_TYPE_MULTIPLE ? <div className={css.sessonRow}>EveryDay</div> : null} */}

                {/* {dayAvailability.length && dayAvailability.map((item, i) => {
                  const { dayOfWeek, startTime, endTime, seats } = item;
                  return <div className={css.sessonRow} key={"sessionRow" + i}>
                    <div>
                      <span className={css.iconImage}>
                        <IconProfileCard type="time" />
                      </span>
                      <span className={css.labelSession}>{dayOfWeek} : {startTime}-{endTime}</span>
                    </div>
                    <div>
                      <span className={css.iconImage}>
                        <IconProfileCard type="user" />
                      </span>
                      <span className={css.iconText}>{seats}</span>
                    </div>
                  </div>
                })
                } */}

                <div className={css.sessonRow}>
                  <div>
                    <span className={css.iconImage}>
                      <IconProfileCard type="time" />
                    </span>
                    <span className={css.labelSession}>{startTime}-{endTime}</span>
                  </div>
                  <div>
                    <span className={css.iconImage}>
                      <IconProfileCard type="user" />
                    </span>
                    <span className={css.iconText}>{surrentSeatBooked}/{seats}</span>
                  </div>
                </div>

                {locationName ? <div className={css.sessonRow}>
                  <div>
                    <span className={css.iconImage}>
                      <IconProfileCard type="location" />
                    </span>
                    <span className={css.labelSession}>{locationName}</span>
                  </div>
                </div> : null}

              </div>
            )
          })
          : null}
      </div>
    </div>
  );
}

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );
  const [activeTab, setActiveTab] = useState(ListingPageTab[0]);

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    sessionListings,
    timeSlots,
    fetchFreeLessonInProgreess,
    freeLessons,
    fetchFreeLessonError,
    onInitiateOrderFreeLesson,
    initiateFreeLessonRequest,
    initiateFreeLessonError,
  } = props;
  moment.locale('en', {
    week: {
      dow: 1
    }
  });
  const isUtem = history?.location?.search || '';
  const hasUtm = isUtem.includes('utm=');
  const [freeLessonModal, setFreeLessonModal] = useState(false);
  const [freeLessonLoader, setFreeLessonLoader] = useState(false);
  const [openCourseDetailModal, setCourseDetailModal] = useState(false);

  useEffect(() => {
    if (!freeLessonModal && hasUtm && isAuthenticated) {
      setFreeLessonModal(true);
    }

    setTimeout(() => {
      if (hasUtm && !isAuthenticated) {
        history.push(createResourceLocatorString('LoginPage', routeConfiguration, {}, {}), { from: location.pathname + location.search });
      }
    }, 1500);

  }, [isAuthenticated]);


  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer currentListingAuthorId={currentListing?.author?.id?.uuid || "1111"} />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (hasUtm && fetchFreeLessonInProgreess) {
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  // const image = currentListing && currentListing[0] && currentListing[0].images && currentListing[0].images.length ? currentListing[0].images[0] : false;
  const { schoolName } = ensuredAuthor?.attributes?.profile?.publicData || {};
  const { learningType, lessonCategory, lessonPacks, quantity = 1, additionalQuestions, dayAvailability } = publicData || {};
  const isSchool = learningType == SCHOOL_TYPE;
  const isFreeLesson = lessonCategory && (lessonCategory === SINGLE_LESSON_CATEGORY && hasUtm) ? true : false;
  const isPack = lessonCategory == PACK_OF_LESSON_CATEGORY;
  const isCourse = lessonCategory == COURSE_LESSON_CATEGORY;
  const isOpenCourseDetail = !!(isCourse && ((additionalQuestions && additionalQuestions.length) || (dayAvailability && dayAvailability.length && dayAvailability.findIndex((st) => st.additionalLinks && st.additionalLinks.length && st.additionalLinks[0].locationName) >= 0)));

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const closeOrderModal = (history, location) => {
    const { pathname, search, state } = location;
    const searchParams = omit(parse(search), 'orderOpen');
    const searchString = `?${stringify(searchParams)}`;
    history.push(`${pathname}${searchString}`, state);
  };

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (!isAuthenticated) {
      const currentLocation = typeof window != "undefined" && window.location.pathname;
      return history.push(createResourceLocatorString("LoginPage", routeConfiguration, {}, {}), { from: currentLocation });
    }
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      isOpenCourseDetail ? setCourseDetailModal(values) : onSubmit(values);
      isOpenCourseDetail ? closeOrderModal(history, location) : null;
    }
  };

  // free lesson transaction
  const handleFreeLessonSubmit = async (values) => {
    if (freeLessonLoader) {
      return
    }
    setFreeLessonLoader(true);
    if (!freeLessonLoader && isFreeLesson && isAuthenticated && (!isSchool)) {
      const { processAlias, listingId, userPackages } = getInitiateOrderData(currentUser, currentListing, values, FREE_OF_LESSON_CATEGORY);
      const listingDataMail = getListingDataForMail(currentListing, currentUser);
      const fnParams = { ...values, userPackages, listingId, listingDataMail: listingDataMail };
      const FREE_LESSON_TRANSISTION = transitions.FREE_LESSON_TRANSISTION;

      return onInitiateOrderFreeLesson(fnParams, processAlias, FREE_LESSON_TRANSISTION)
        .then((response) => {
          return history.push(createResourceLocatorString("OrderDetailsPageRedirect", routeConfiguration, { id: response.data.data.id.uuid }, {}));
        })
        .catch(e => {
          setFreeLessonModal(false);
          setFreeLessonLoader(false);
          return history.push(
            createResourceLocatorString('ListingPage',
              routeConfiguration,
              params,
              {}
            ));
        });


    };
  }

  const onCloseFreeLessonModal = () => {
    setFreeLessonModal(false);
    return history.push(
      createResourceLocatorString(
        'ListingPage',
        routeConfiguration,
        params,
        {}));
  }

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
      price: intl.formatNumber(convertMoneyToNumber(price), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: price.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        {currentListing.id ? (
          <ActionBarMaybe
            className={css.actionBarForProductLayout}
            isOwnListing={isOwnListing}
            listing={currentListing}
            editParams={{
              id: listingId.uuid,
              slug: listingSlug,
              type: listingPathParamType,
              tab: listingTab,
            }}
          />
        ) : null}
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>
            <div className={css.sliderWrapper}>
              <SectionGallery
                listing={currentListing}
                variantPrefix={config.layout.listingImage.variantPrefix}
              />
            </div>
            <div className={css.mainWrapper}>
              <div className={css.headingCaption}>{schoolName}</div>
              <div className={css.mobileHeading}>
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              </div>
              <SectionTextMaybe text={publicData && publicData?.shortDescription} showAsIngress />

              {isPack && lessonPacks && lessonPacks.length
                ? <div className={css.lessonPackBox}>
                  <ul className={css.firstOrder}>
                    <li><FormattedMessage id="ListingPage.packTitle" /> {publicData.quantity}  <FormattedMessage id="ListingPage.session" /> </li>
                    <li>  <FormattedMessage id="ListingPage.sessionExchange" /></li>
                    <ul>
                      {lessonPacks.map((st) => <li>{st.title}</li>)}
                    </ul>
                  </ul>
                </div>
                : null}
              <div className={css.descriptionTabs}>
                <div className={css.tabButtons}>
                  {ListingPageTab.map((st, index) => <button
                    type='buttton'
                    key={index + st}
                    onClick={(e) => {
                      e.preventDefault()
                      setActiveTab(st)
                    }}
                    className={activeTab === st ? css.activeTab : ''}
                  >
                    {st}
                  </button>
                  )}
                </div>
                <div className="tab-content">
                  {activeTab === ListingPageTab[2] ? (
                    <div className={css.tabPane}>
                      <div className={css.paneDescription}>{publicData?.cancelPolicy}</div>
                    </div>
                  )
                    : activeTab === ListingPageTab[1] ? (
                      <div className={css.tabPane}>
                        <div className={css.paneDescription}>
                          <SessionCard sessionListings={sessionListings} listing={currentListing} timeSlots={timeSlots} />
                        </div>
                      </div>
                    )
                      // : activeTab === 3 ? (
                      //   <div className={css.tabPane}>
                      //     <div className={css.paneDescription}>
                      //       {isSchool ? null : <>
                      //         <div className={css.orderMap}>
                      //           <SectionMapMaybe
                      //             geolocation={geolocation}
                      //             publicData={currentListing?.attributes?.publicData}
                      //             listingId={currentListing.id}
                      //             mapsConfig={config.maps}
                      //           />
                      //         </div>
                      //       </>}
                      //     </div>
                      //   </div>
                      // )
                      : activeTab === ListingPageTab[0] ? < div className={css.tabPane}>
                        <div className={css.paneDescription}>
                          {description}
                        </div>
                      </div> : null}
                </div>
              </div>
              <SectionDetailsMaybe
                publicData={publicData}
                metadata={metadata}
                listingConfig={listingConfig}
                intl={intl}
              />
              {listingConfig.listingFields.reduce((pickedElements, config) => {
                const { key, enumOptions, scope = 'public' } = config;
                const value =
                  scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
                const hasValue = value !== null;
                return hasValue && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                  ? [
                    ...pickedElements,
                    <SectionMultiEnumMaybe
                      key={key}
                      heading={config?.showConfig?.label}
                      options={createFilterOptions(enumOptions)}
                      selectedOptions={value}
                    />,
                  ]
                  : hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                    ? [
                      ...pickedElements,
                      <SectionTextMaybe key={key} heading={config?.showConfig?.label} text={value} />,
                    ]
                    : pickedElements;
              }, [])}
              <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
              {/* <SectionAuthorMaybe
                title={title}
                listing={currentListing}
                authorDisplayName={authorDisplayName}
                onContactUser={onContactUser}
                isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
                onCloseInquiryModal={() => setInquiryModalOpen(false)}
                sendInquiryError={sendInquiryError}
                sendInquiryInProgress={sendInquiryInProgress}
                onSubmitInquiry={onSubmitInquiry}
                currentUser={currentUser}
                onManageDisableScrolling={onManageDisableScrolling}
              /> */}
            </div>
          </div>
          <div className={css.orderColumnForProductLayout}>
            <OrderPanel
              className={css.productOrderPanel}
              listing={currentListing}
              geolocation={geolocation}
              isOwnListing={isOwnListing}
              config={config}
              onSubmit={handleOrderSubmit}
              authorLink={
                <NamedLink
                  className={css.authorNameLink}
                  name="ListingPage"
                  params={params}
                  to={{ hash: '#author' }}
                >
                  {authorDisplayName}
                </NamedLink>
              }
              title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
              titleDesktop={
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              }
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              onContactUser={onContactUser}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
              marketplaceName={config.marketplaceName}
              sessionListings={sessionListings}
              currentUser={currentUser}
              isAuthenticated={isAuthenticated}
            />
          </div>
        </div>
        <div className={css.freeLessonModal}>
          <Modal
            id={"ListingPage.FreeLessonCardModal"}
            isOpen={freeLessonModal}
            onClose={() => { }}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <FreeLessonCard
              currentListing={currentListing}
              currentUser={currentUser}
              fetchFreeLessonInProgreess={fetchFreeLessonInProgreess}
              freeLessons={freeLessons}
              fetchFreeLessonError={fetchFreeLessonError}
              handleFreeLessonSubmit={handleFreeLessonSubmit}
              onCloseFreeLessonModal={onCloseFreeLessonModal}
              isSchool={isSchool}
              inProgress={initiateFreeLessonRequest || freeLessonLoader}
              initiateFreeLessonError={initiateFreeLessonError}
            />
          </Modal>
        </div>

        <Modal
          id={"ListingPage.setCourseDetailModal"}
          isOpen={openCourseDetailModal}
          onClose={() => { setCourseDetailModal(false) }}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {openCourseDetailModal ?
            <JoinCourseDetaiForm
              currentUser={currentUser}
              title={title}
              onSubmit={(values, form) => {
                onSubmit(values)
              } }
              daysOfWeek={dayAvailability ? dayAvailability.map((st) => st.day) : []}
              initialValues={{
                ...openCourseDetailModal,
                additionalPicupPoints: dayAvailability ? dayAvailability.filter((st) => st.additionalLinks && st.additionalLinks.length).map((st) => ({ day: st.day, additionalLinks: st.additionalLinks || [] })) : [],
                additionalQuestions: (additionalQuestions && additionalQuestions.length) ? additionalQuestions.map((st) => {
                  const { question } = st;
                  const { customQuestion = [] } = currentUser?.attributes?.profile?.publicData;
                  const getAnswer = (Array.isArray(customQuestion) && customQuestion.find((st) => st.question === question)) || false;
                  return { ...st, value: getAnswer ? getAnswer.answer : "" };
                }) : null
              }}
              inProgress={false}
            />
            : <div></div>}

        </Modal>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    sessionListings,
    timeSlots,
    fetchFreeLessonInProgreess,
    freeLessons,
    fetchFreeLessonError
  } = state.ListingPage;

  const { initiateFreeLessonRequest, initiateFreeLessonError } = state.CheckoutPage;
  const listingsSession = getListingsById(state, sessionListings);

  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };


  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    sessionListings: listingsSession,
    timeSlots,
    freeLessons,
    fetchFreeLessonError,
    fetchFreeLessonInProgreess,
    initiateFreeLessonRequest,
    initiateFreeLessonError
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onInitiateOrderFreeLesson: (fnParams, processAlias, requestTransition) =>
    dispatch(onFreeLessonIntiateOrder(fnParams, processAlias, requestTransition)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
