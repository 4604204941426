import React from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import {
  Button,
  Form,
  FieldTextInput,
  FieldSelect
} from '..';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import css from './JoinCourseDetaiForm.module.css';


const spanishDays = [
  { day: "Monday", sDay: "Lunes" }, { day: "Tuesday", sDay: "Martes" }, { day: "Wednesday", sDay: "Miércoles" }, { day: "Thursday", sDay: "Jueves" }, { day: "Friday", sDay: "Viernes" }, { day: "Saturday", sDay: "Sábado" }, { day: "Sunday", sDay: "Domingo" }
];


const JoinCourseDetaiFormComponent = props => {
  const {
    onSubmit,
    title,
    ...restOfProps
  } = props;

  return (
    <FinalForm
      {...restOfProps}
      onSubmit={(values, form) => onSubmit({ ...values }, form)}
      mutators={{
        ...arrayMutators,
      }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          children,
          disabled,
          handleSubmit,
          inProgress,
          intl,
          invalid,
          pristine,
          classes,
          autoFocus,
          ready,
          form,
          formId,
          values,
          lessonTitle,
        } = fieldRenderProps;

        return (
          <Form className={classes} onSubmit={(values) => handleSubmit(values, form)}>
            <h4> {title} </h4>

            <FieldArray
              name={`additionalPicupPoints`}
              render={({ fields }) => (
                <React.Fragment>
                <h5>
                <b>
                Selecciona un punto de recogida (opcional)
                </b>
                </h5>
                  {fields.map((name, index) => {
                    const { additionalLinks, day } = values && Array.isArray(values.additionalPicupPoints) ? values.additionalPicupPoints[index] : {};
                    const selectedDay = spanishDays.filter(st => st.day == day).map(st => st.sDay).toString();

                    return <div>
                      <FieldSelect
                        name={`${name}.userPickupPoint`}
                        id={`${name}.userPickupPoint`}
                        className={css.inputBox}
                        label={selectedDay}
                        onChange={(e) => {
                          form.change(name + ".userPickupPoint", e);
                          if (e) {
                            const filterdValue = additionalLinks.find((st) => st.locationName == e);
                            form.change(name + ".userPickupPointTime", filterdValue.pickupTime);
                            form.change(name + ".userPickupPointAddress", filterdValue.location);
                            form.change(name + ".userPickupPointURL", filterdValue.locationURL);
                          }
                        }}
                      >
                        <option value="" selected>
                          {intl.formatMessage({ id: 'AdditionalQuestionForm.selectPickupPoint' })}
                        </option>
                        {additionalLinks && additionalLinks.filter((st) => st.locationName).map((st, i) => <option key={st.locationName + i} value={st.locationName}>{st.locationName}</option>)}
                      </FieldSelect>
                    </div>
                  })}
                </React.Fragment>
              )}
            />

            <div className={css.bottomButton}>
              {values && values.additionalQuestions && values.additionalQuestions.length ? <>
                <h5><b>Preguntas para tu curso</b></h5>
                <FieldArray
                  name={`additionalQuestions`}
                  render={({ fields }) => (
                    <React.Fragment>

                      {fields.map((name, index) => {
                        if (values.additionalQuestions[index].questionType == "boolean") {
                          return <FieldSelect
                            name={`${name}.value`}
                            id={`${name}.value`}
                            className={css.inputBox}
                            label={values.additionalQuestions[index].question}
                            // validate={requiredAndNonEmptyString}
                          >
                            <option value="" selected>
                              {intl.formatMessage({ id: 'AdditionalQuestionForm.select' })}
                            </option>
                            <option value={"yes"}>{intl.formatMessage({ id: 'AdditionalQuestionForm.yesLabel' })}</option>
                            <option value={"no"}>{intl.formatMessage({ id: 'AdditionalQuestionForm.noLabel' })}</option>
                          </FieldSelect>
                        } else if (values.additionalQuestions[index].questionType == "select" && values.additionalQuestions[index].questionOption && values.additionalQuestions[index].questionOption.length) {
                          return <FieldSelect
                            name={`${name}.value`}
                            id={`${name}.value`}
                            className={css.inputBox}
                            label={values.additionalQuestions[index].question}
                            // validate={requiredAndNonEmptyString}
                          >
                            <option value="" selected>
                              {intl.formatMessage({ id: 'AdditionalQuestionForm.select' })}
                            </option>
                            {values.additionalQuestions[index].questionOption.map((itt) => <option value={itt}>{itt}</option>)}

                          </FieldSelect>
                        }
                        return (<div key={"additionalQuestions" + index}>
                          <FieldTextInput
                            className={css.inputBox}
                            type={values.additionalQuestions[index].questionType}
                            label={values.additionalQuestions[index].question}

                            name={`${name}.value`}
                            id={`${name}.value`}
                          />
                        </div>
                        )
                      })}
                    </React.Fragment>
                  )}
                />
              </> : null}

              <Button
                type="submit"
                inProgress={false}
                // disabled={submitDisabled}
                ready={ready}
              >
                <FormattedMessage id="ProductOrderForm.ctaButton" />
              </Button>
            </div>
          </Form>
        )
      }}
    />
  );
};

JoinCourseDetaiFormComponent.defaultProps = {
  className: null,
  disabled: false,
  inProgress: false,
  ready: false,
  fieldRenderProps: null,
};

JoinCourseDetaiFormComponent.propTypes = {
  className: string,
  disabled: bool,
  inProgress: bool,
  ready: bool,
  fieldRenderProps: shape({
    handleSubmit: func,
    invalid: bool,
    pristine: bool,
    values: object,
  }),

};

const JoinCourseDetaiForm = compose(injectIntl)(JoinCourseDetaiFormComponent);

export default JoinCourseDetaiForm;
